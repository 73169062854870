import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import SideBar from '../components/sidebar';
import { graphql, Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';

export default ({ data }) => {
	const post = data.markdownRemark;
	return (
		<Layout>
			<SEO title={post.frontmatter.title} />
			<SideBar />
			<div className="content posts-container">
				<div className="single-post">
					<h1>{post.frontmatter.title}</h1>
					<p className="date">Published on: {post.frontmatter.date}</p>
					<hr className="hr-style" />
					<div dangerouslySetInnerHTML={{ __html: post.html }} />
					<div className="tags">
						<span className="font-bold mr-2 my-2 inline-block">Tags: </span>
						{post.frontmatter.tags.map((tag, index) => {
							return (
								<Link key={index} className="" to={`/tags/${kebabCase(tag)}`}>
									{tag}
								</Link>
							);
						})}
					</div>
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`
	query($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				title
				date(formatString: "DD MMMM YYYY")
				tags
			}
		}
	}
`;
